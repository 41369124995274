var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          attrs: { loading: _vm.isLoading, "slider-color": "secondary" },
          model: {
            value: _vm.activeOrganization,
            callback: function($$v) {
              _vm.activeOrganization = $$v
            },
            expression: "activeOrganization"
          }
        },
        _vm._l(_vm.organizations, function(organization) {
          return _c(
            "v-tab",
            {
              key: organization.id,
              staticClass: "tab__filled",
              class: {
                active: _vm.activeOrganization === "" + organization.id
              },
              attrs: { href: "#" + organization.id },
              on: {
                click: function($event) {
                  return _vm.handleTabChange("" + organization.id)
                }
              }
            },
            [_vm._v(" " + _vm._s(organization.name) + " ")]
          )
        }),
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.activeOrganization,
            callback: function($$v) {
              _vm.activeOrganization = $$v
            },
            expression: "activeOrganization"
          }
        },
        _vm._l(_vm.organizations, function(organization) {
          return _c(
            "v-tab-item",
            {
              key: organization.id,
              attrs: {
                transition: false,
                "reverse-transition": false,
                lazy: true,
                value: "" + organization.id
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "elementPanel elementPanel--spaced fill-height"
                },
                [_vm._t("default")],
                2
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }