import { Component, Vue, Watch } from 'vue-property-decorator';
import HourTariffMatrix from '@/components/HourTariffMatrix/HourTariffMatrix.vue';
import OrganizationsTab from '@/components/OrganizationsTab/OrganizationsTab.vue';
import { ParsedTariffMatrix, parseMatrix, TariffMatrix } from '@/components/HourTariffMatrix/HourTariffMatrix';
import { AxiosResponse } from 'axios';
import { Rpc } from '@/models/Rpc';

@Component<Tariffs>({
  components: {
    HourTariffMatrix,
    OrganizationsTab,
  },
})
export default class Tariffs extends Vue {
  public $pageTitle = 'Tarieven';

  protected activeOrganization = '';

  protected matrix: TariffMatrix | null = null;

  protected parsedMatrix: ParsedTariffMatrix | null = null;

  protected isLoadingTariffMatrix = false;

  protected isInitialTabLoad = false;

  protected mounted(): void {
    this.initialize();
  }

  protected handleUpdatedMatrix() {
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.isLoadingTariffMatrix = true;
    await this.getTariffs();
    this.isLoadingTariffMatrix = false;
  }

  protected async getTariffs() {
    if ((! this.activeOrganization || ! this.activeOrganization.length) && this.$store.state.isServiceOrganization) {
      return;
    }

    const response: null | AxiosResponse = await new Rpc()
      .dmz(this.activeOrganization)
      .rpcPost({
        signature: 'tariffs:get-matrix',
        body: {
          user_id: null,
        },
      }, false);

    if (response && response.data) {
      this.matrix = response.data;
      this.parsedMatrix = parseMatrix(this.matrix as TariffMatrix);
    }
  }

  protected emitBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Tarieven' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any): void {
    this.emitBreadcrumb();
  }

  @Watch('activeOrganization')
  public organizationChanged(): void {
    this.initialize();
  }
}
