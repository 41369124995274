import { Component, Vue, Prop } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { Organization } from '@/models/Organization';

@Component<OrganizationsTab>({})
export default class OrganizationsTab extends Vue {
  @Prop()
  protected types!: string[];

  protected activeOrganization = '';

  protected organizations: Organization[] = [];

  protected isLoading = false;

  public mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    await this.getOrganizations();

    this.isLoading = false;
  }

  protected handleTabChange(organizationId: string) {
    this.activeOrganization = organizationId;
    this.$emit('input', organizationId);
    this.$emit('change');
  }

  protected async getOrganizations(): Promise<void> {
    try {
      // If organization types are specified filter them. Returns all experts by default.
      const organizations = this.types ? await new Organization().filter({ types: this.types }).all() : await new Organization().getAllExperts();
      this.organizations = organizations;
      if (Object.prototype.hasOwnProperty.call(organizations[0], 'id')) {
        this.handleTabChange(organizations[0].id);
      }
    } catch (error) {
      ErrorHandler.network(error);
    }
  }
}
