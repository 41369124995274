var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { wrap: "", "align-bottom": "" } },
    [
      [
        _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
          _c("h2", { staticClass: "elementSubTitle" }, [_vm._v("Tarieven")])
        ])
      ],
      _c(
        "v-flex",
        { attrs: { xs12: "", md12: "" } },
        [
          _vm.$store.state.isServiceOrganization
            ? [
                _c(
                  "OrganizationsTab",
                  {
                    model: {
                      value: _vm.activeOrganization,
                      callback: function($$v) {
                        _vm.activeOrganization = $$v
                      },
                      expression: "activeOrganization"
                    }
                  },
                  [
                    !_vm.isLoadingTariffMatrix
                      ? _c("HourTariffMatrix", {
                          attrs: {
                            isAutoSave: false,
                            organizationId: _vm.activeOrganization
                          },
                          on: { updatedMatrix: _vm.handleUpdatedMatrix },
                          model: {
                            value: _vm.parsedMatrix,
                            callback: function($$v) {
                              _vm.parsedMatrix = $$v
                            },
                            expression: "parsedMatrix"
                          }
                        })
                      : _vm._e(),
                    _vm.isLoadingTariffMatrix
                      ? _c("ALoader", {
                          staticClass: "tw-mt-64",
                          attrs: { minHeight: "300px" }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            : _vm._e(),
          !_vm.$store.state.isServiceOrganization
            ? [
                !_vm.isLoadingTariffMatrix
                  ? _c("HourTariffMatrix", {
                      attrs: { isAutoSave: false },
                      on: { updatedMatrix: _vm.handleUpdatedMatrix },
                      model: {
                        value: _vm.parsedMatrix,
                        callback: function($$v) {
                          _vm.parsedMatrix = $$v
                        },
                        expression: "parsedMatrix"
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }